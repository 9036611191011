import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PageSizes, PaginatorState} from '../../models/paginator.model';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
    @Input() paginator: PaginatorState;
    @Input() isLoading;
    @Output() paginate: EventEmitter<PaginatorState> = new EventEmitter();
    @Output() pageRout = new EventEmitter<number>();
    public currentPage;

    constructor() {
    }

    ngOnInit(): void {
    }


    pageChange(num: number) {
      this.pageSet(num)
        this.paginator.page = num;
        this.paginate.emit(this.paginator);
    }

    pageSet(page: number) {
      this.pageRout.emit(page);
      this.currentPage = page;

    }

    sizeChange() {
        this.paginator.pageSize = +this.paginator.pageSize;
        this.paginator.page = 1;
        this.paginate.emit(this.paginator);
    }
}
